// export const BASE_URL = "http://localhost:8000/api/";
export const BASE_URL = "https://hazrixpertbackend.xpertsgroup.net/api/";

export const LOGIN = BASE_URL + "login";
export const LOGOUT = BASE_URL + "logout";

export const ORGANIZATIONS = BASE_URL + "organizations";
export const STORE_ORGANIZATION = BASE_URL + "organizations/store";

export const STORE_PROGRAM = BASE_URL + "programs/store";
