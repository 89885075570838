export const isAuthenticated = () => !!localStorage.getItem("loginUserInfo");

export const getUserRole = () => {
	const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
	// return loginUserInfo ? loginUserInfo.user.role.code : null; 
	return loginUserInfo ? loginUserInfo.user.role.code.replace(/_/g, '-') : null;
};

export const getUserData = () => JSON.parse(localStorage.getItem("loginUserInfo"));

export const login = (data) => {
	localStorage.setItem("loginUserInfo", JSON.stringify(data));
};

export const logout = () => {
	localStorage.removeItem("loginUserInfo");
};
