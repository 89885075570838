import React, { useState } from "react";
import * as Components from "../../Components";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Contexts/AuthContext";
import Data from "../../Data/index.json";

const CreateSesssion = () => {
	document.title = "Create New Session || Hazri Xpert";

	const [formFields, setFormFields] = useState({
		name: "",
		program: "",
		description: "",
	});
	const [errors, setErrors] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [alertShow, setAlertShow] = useState(false);
	const [jsonMessage, setJsonMessage] = useState("");

	const navigate = useNavigate();
	const { role } = useAuth();

	const validateFields = () => {
		const newErrors = {};
		if (!formFields.name) {
			newErrors.name = "Class name is required.";
		}
		if (!formFields.program) {
			newErrors.program = "Program is required.";
		}
		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const onHandleSubmit = async () => {
		setIsLoading(true);
		if (!validateFields()) {
			setIsLoading(false);
			return;
		}
		try {
			// await axios.post(API_CONSTANTS.STORE_CLASS, formFields);

			setIsLoading(false);
			setAlertShow(false);
			navigate(`/${role}/programs`);
		} catch (error) {
			setIsLoading(false);
			setAlertShow(true);
			setJsonMessage(
				error.response ? error.response.data.message : error.message
			);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormFields((prev) => ({ ...prev, [name]: value }));

		setErrors((prev) => ({ ...prev, [name]: value ? "" : errors[name] }));
	};

	return (
		<div className="container-fluid">
			<div className="nk-content-inner">
				<div className="nk-content-body">
					<Components.PageHeader
						headingText="Create New Session"
						buttonText="Sessions List"
						buttonIcon="list"
						buttonLink={`/${role}/sessions`}
					/>

					<div className="nk-block">
						<div className="card shadow-sm card-stretch">
							<div className="card-inner">
								{alertShow && (
									<Components.Alert
										customClass="col-md-6"
										type="danger"
										icon="ni-cross-circle"
										message={jsonMessage}
										onClose={() => setAlertShow(false)}
									/>
								)}

								<div className="row">
									<div className="col-md-4 col-12 mb-3">
										<Components.UnifiedInputField
											label="Name"
											type="text"
											name="name"
											value={formFields.name}
											onChange={handleChange}
											error={errors.name}
											placeholder="Enter Name"
										/>
									</div>

									<div className="col-md-4 col-12 mb-3">
										<Components.UnifiedInputField
											inputType="select"
											label="Program"
											name="program"
											value={formFields.program}
											onChange={handleChange}
											error={errors.program}
											placeholder="Select Program"
											options={Data.programs}
										/>
									</div>

									<div className="col-md-4 col-12 mb-3">
										<Components.UnifiedInputField
											inputType="select"
											label="Class"
											name="class"
											value={formFields.class}
											onChange={handleChange}
											error={errors.class}
											placeholder="Select Class"
											options={Data.classes}
										/>
									</div>

									<div className="col-md-4 col-12 mb-3">
										<Components.UnifiedInputField
											inputType="select"
											label="Section"
											name="section"
											value={formFields.section}
											onChange={handleChange}
											error={errors.section}
											placeholder="Select Section"
											options={Data.teachers}
										/>
									</div>

									<div className="col-md-4 col-12 mb-3">
										<Components.UnifiedInputField
											label="Increment"
											type="number"
											name="increment"
											value={formFields.increment}
											onChange={handleChange}
											error={errors.increment}
											placeholder="Enter Increment"
										/>
									</div>

									<div className="col-md-4 col-12 mb-3">
										<Components.UnifiedInputField
											label="Start Date"
											type="date"
											name="start_date"
											value={formFields.start_date}
											onChange={handleChange}
											error={errors.start_date}
											placeholder="Enter Start Date"
										/>
									</div>
								</div>
								<hr />

								<Components.Button
									isLoading={isLoading}
									onClick={() => navigate(-1)}
									label="Cancel"
									className="btn btn_danger"
								/>
								<Components.Button
									isLoading={isLoading}
									onClick={onHandleSubmit}
									label="Submit"
									className="btn btn_accent ml-1"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CreateSesssion ;
