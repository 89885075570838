import React, { useState } from "react";
import * as Components from "../../Components";
import Data from "../../Data";
import DummyImage from "../../Assets/backend/images/dummey_profile.jpeg";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Contexts/AuthContext";
import * as API_CONSTANTS from "../../ApiConstants/index";
import axios from "axios";
import { IoCheckmark } from "react-icons/io5";

const CreateOrganization = () => {
  document.title = "Create New Organization || Hazri Xpert";

  const [formFields, setFormFields] = useState({
    organization_name: "",
    organization_email_address: "",
    organization_contact_no: "",
    organization_address: "",
    owner_full_name: "",
    owner_email_address: "",
    owner_contact_no: "",
    owner_password: "",
    owner_gender: "",
    owner_date_of_birth: "",
    owner_image: "",
    owner_address: "",
    owner_about_information: "",
  });

  const [password_type, setPasswordType] = useState("password");
  const [owner_image_preview, setOwnerImagePreview] = useState(DummyImage);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [alertShow, setAlertShow] = useState(false);
  const [jsonMessage, setJsonMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");

  const navigate = useNavigate();
  const { user, role } = useAuth();

  const togglePassword = () =>
    setPasswordType(password_type === "password" ? "text" : "password");

  const getModalContent = () => {
    switch (modalType) {
      case "success":
        return {
			heading: "Organization Created",
			text: "Your organization has been successfully created!",
			subText: "You can now see it in the organization list.",
			icon: (
			  <IoCheckmark className="nk-modal-icon icon icon-circle icon-circle-xxl bg-success" />
			),
			dangerButtontext: "Cancel",
			accentButtontext: "Go To Organizations List",
		  };
      default:
        return {};
    }
  };
  const { heading, text, subText, icon, dangerButtontext, accentButtontext } =
    getModalContent();
	console.log(subText)

  const patterns = {
    name: /^[A-Za-z0-9\s]{3,50}$/,
    full_name: /^[A-Za-z\s]{3,50}$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)\S{8,15}$/,
    email_address:
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
  };
  const onNavigateToListPage = () => {
	navigate(`/${role + "/organizations"}`);
  } 

  const validateField = (name, value) => {
    switch (name) {
      case "organization_name":
        return value
          ? patterns.name.test(value)
            ? ""
            : "Organization name must be between 3 to 50 characters."
          : "Organization name is required.";
      case "organization_email_address":
        return value
          ? patterns.email_address.test(value)
            ? ""
            : "Invalid organization email address, Only letters (a-z), numbers (0-9) and period (.) are allowed."
          : "Organization email address is required.";
      case "organization_contact_no":
        return value
          ? ""
          : "Invalid organization contact number. Please ensure it includes the country code and follows the correct format.";
      case "organization_address":
        return value ? "" : "Organization address is required.";
      case "owner_full_name":
        return value
          ? patterns.full_name.test(value)
            ? ""
            : "Owner full name must be between 3 to 50 characters and contain only alphabets."
          : "Owner full name is required.";
      case "owner_email_address":
        return value
          ? patterns.email_address.test(value)
            ? ""
            : "Invalid owner email address, Only letters (a-z), numbers (0-9) and period (.) are allowed."
          : "Owner email address is required.";
      case "owner_contact_no":
        return value
          ? ""
          : "Invalid owner contact number. Please ensure it includes the country code and follows the correct format.";
      case "owner_password":
        return value
          ? patterns.password.test(value)
            ? ""
            : "Owner password must contain at least one uppercase, one lowercase, one number, and be between 8 to 15 characters."
          : "Owner password is required.";
      default:
        return "";
    }
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      const file = files[0];
      setFormFields((prev) => ({ ...prev, [name]: file }));

      const reader = new FileReader();
      reader.onloadend = () => {
        setOwnerImagePreview(reader.result);
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    } else {
      setFormFields((prev) => ({ ...prev, [name]: value }));
      const error = validateField(name, value);
      setErrors((prev) => ({ ...prev, [name]: error }));
    }
  };

  const validateFields = () => {
    const newErrors = {};
    for (const [key, value] of Object.entries(formFields)) {
      const error = validateField(key, value);
      if (error) newErrors[key] = error;
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleContactNoChange = (value, country, field) => {
    const dialCodeLength = country.dialCode.length;
    const formatLength = country.format.replace(/ /g, "").length;
    const isValidLength = value.length + dialCodeLength === formatLength;

    setFormFields((prev) => ({ ...prev, [field]: value }));
    const error = isValidLength
      ? null
      : field === "organization_contact_no"
      ? "Invalid organization contact number. Please ensure it includes the country code and follows the correct format."
      : field === "owner_contact_no"
      ? "Invalid owner contact number. Please ensure it includes the country code and follows the correct format."
      : null;
    setErrors((prev) => ({ ...prev, [field]: error }));
  };

  const onHandleSubmit = async () => {
    setIsLoading(true);

    if (!validateFields()) {
      setIsLoading(false);
      return;
    }

    const formData = new FormData();
    Object.entries(formFields).forEach(([key, value]) => {
      formData.append(key, value);
    });

    formData.append("name", formFields.organization_name);
    formData.append("email_address", formFields.organization_email_address);
    formData.append("contact_no", formFields.organization_contact_no);
    formData.append("address", formFields.organization_address);
    formData.append("user_full_name", formFields.owner_full_name);
    formData.append("user_email_address", formFields.owner_email_address);
    formData.append("user_contact_no", formFields.owner_contact_no);
    formData.append("user_password", formFields.owner_password);
    formData.append("user_gender", formFields.owner_gender);
    formData.append("user_date_of_birth", formFields.owner_date_of_birth);
    formData.append("user_image", formFields.owner_image);
    formData.append("user_address", formFields.owner_address);
    formData.append(
      "user_about_information",
      formFields.owner_about_information
    );

    try {
      const response = await axios({
        method: "POST",
        url: API_CONSTANTS.STORE_ORGANIZATION,
        headers: {
          Authorization: `${user.token.type} ${user.token.value}`,
        },
        data: formData,
      });

      if (response.data.status === true) {
        setIsLoading(false);
        setAlertShow(false);

		setModalType('success');
		setShowModal(true);
      } else {
        setIsLoading(false);
        setAlertShow(true);
        setJsonMessage(response.data.message);
      }
    } catch (error) {
      const errorMessage =
        error.response?.data.message ||
        "Network error. Please try again later.";
      setAlertShow(true);
      setJsonMessage(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="nk-content-inner">
          <div className="nk-content-body">
            <Components.PageHeader
              headingText="Create New Organization"
              buttonText="Organizations List"
              buttonIcon="list"
              buttonLink={`/${role}/organizations`}
            />

            <div className="nk-block">
              <div className="card shadow-sm card-stretch">
                <div className="card-inner">
                  {alertShow === true && (
                    <Components.Alert
                      customClass="col-md-6"
                      type="danger"
                      icon="ni-cross-circle"
                      message={jsonMessage}
                      onClose={() => setAlertShow(false)}
                    />
                  )}

                  <div className="row">
                    <div className="col-12">
                      <h5 className="text-light bg-dark p-2 rounded">
                        Organization Information:
                      </h5>
                    </div>

                    <div className="col-md-4 col-12 mt-3">
                      <Components.UnifiedInputField
                        label="Name"
                        type="text"
                        name="organization_name"
                        value={formFields.organization_name}
                        onChange={handleChange}
                        error={errors.organization_name}
                        placeholder="Enter Organization Name"
                        required={true}
                      />
                    </div>

                    <div className="col-md-4 col-12 mt-3">
                      <Components.UnifiedInputField
                        inputType="input"
                        label="Email Address"
                        type="text"
                        name="organization_email_address"
                        value={formFields.organization_email_address}
                        onChange={handleChange}
                        error={errors.organization_email_address}
                        placeholder="Enter Organization Email Address"
                        required={true}
                      />
                    </div>

                    <div className="col-md-4 col-12 mt-3">
                      <Components.UnifiedInputField
                        inputType="phone"
                        label="Contact Number"
                        type="text"
                        name="organization_contact_no"
                        value={formFields.organization_contact_no}
                        onChange={(value, country) =>
                          handleContactNoChange(
                            value,
                            country,
                            "organization_contact_no"
                          )
                        }
                        placeholder="Enter Organization Contact Number"
                        error={errors.organization_contact_no}
                        className="phone_input"
                        country={"pk"}
                        enableSearch={true}
                        required={true}
                      />
                    </div>

                    <div className="col-12 mt-3">
                      <Components.UnifiedInputField
                        inputType="ckeditor"
                        label="Address"
                        type="textarea"
                        name="organization_address"
                        value={formFields.organization_address}
                        onChange={handleChange}
                        error={errors.organization_address}
                        placeholder="Enter Organization Address"
                        required={true}
                      />
                    </div>

                    <hr />
                    <div className="col-12 mt-3">
                      <h5 className="text-light bg-dark p-2 rounded">
                        Organization Owner/Admin Information:
                      </h5>
                    </div>

                    <div className="col-md-4 col-12 mt-3">
                      <Components.UnifiedInputField
                        label="Full Name"
                        type="text"
                        name="owner_full_name"
                        value={formFields.owner_full_name}
                        onChange={handleChange}
                        error={errors.owner_full_name}
                        placeholder="Enter Owner Full Name"
                        required={true}
                      />
                    </div>

                    <div className="col-md-4 col-12 mt-3">
                      <Components.UnifiedInputField
                        label="Email Address"
                        type="email"
                        name="owner_email_address"
                        value={formFields.owner_email_address}
                        onChange={handleChange}
                        error={errors.owner_email_address}
                        placeholder="Enter Owner Email Address"
                        required={true}
                      />
                    </div>

                    <div className="col-md-4 col-12 mt-3">
                      <Components.UnifiedInputField
                        inputType="phone"
                        label="Contact Number"
                        type="text"
                        name="owner_contact_no"
                        value={formFields.owner_contact_no}
                        onChange={(value, country) =>
                          handleContactNoChange(
                            value,
                            country,
                            "owner_contact_no"
                          )
                        }
                        placeholder="Enter Owner Contact Number"
                        error={errors.owner_contact_no}
                        className="phone_input"
                        country={"pk"}
                        enableSearch={true}
                        required={true}
                      />
                    </div>

                    <div className="col-md-4 col-12 mt-3">
                      <Components.UnifiedInputField
                        label="Password"
                        type="password"
                        name="owner_password"
                        value={formFields.owner_password}
                        onChange={handleChange}
                        error={errors.owner_password}
                        placeholder="Enter Owner Password"
                        togglePassword={togglePassword}
                        password_type={password_type}
                        required={true}
                      />
                    </div>

                    <div className="col-md-4 col-12 mt-3">
                      <Components.UnifiedInputField
                        inputType="select"
                        label="Gender"
                        type="select"
                        name="owner_gender"
                        value={formFields.owner_gender}
                        onChange={handleChange}
                        error={errors.owner_gender}
                        placeholder="Select Owner Gender"
                        required={false}
                        options={Data.gender}
                      />
                    </div>

                    <div className="col-md-4 col-12 mt-3">
                      <Components.UnifiedInputField
                        label="Date of Birth"
                        type="date"
                        name="owner_date_of_birth"
                        value={formFields.owner_date_of_birth}
                        onChange={handleChange}
                        error={errors.owner_date_of_birth}
                        placeholder="Enter Owner Date of Birth"
                        required={false}
                      />
                    </div>

                    <div className="col-md-11 col-12 mt-3">
                      <Components.UnifiedInputField
                        inputType="file"
                        label="Image"
                        type="file"
                        name="owner_image"
                        onChange={handleChange}
                        error={errors.owner_image}
                        placeholder="Upload Owner Image"
                        required={false}
                        accept="image/*"
                      />
                    </div>

                    <div className="col-12 col-md-1 mt-4 text-center">
                      <img
                        src={owner_image_preview}
                        alt={`Owner_Admin_Image`}
                        className="rounded-circle"
                        height="80"
                        width="80"
                      />
                    </div>

                    <div className="col-12 mt-3">
                      <Components.UnifiedInputField
                        inputType="ckeditor"
                        label="Address"
                        type="textarea"
                        name="owner_address"
                        value={formFields.owner_address}
                        onChange={handleChange}
                        error={errors.owner_address}
                        placeholder="Enter Owner Address"
                      />
                    </div>

                    <div className="col-12 my-3">
                      <Components.UnifiedInputField
                        inputType="ckeditor"
                        label="About/Bio Information"
                        type="textarea"
                        name="owner_about_information"
                        value={formFields.owner_about_information}
                        onChange={handleChange}
                        error={errors.owner_about_information}
                        placeholder="Enter Owner About/Bio Information"
                      />
                    </div>
                  </div>

                  <hr />
                  <Components.Button
                    onClick={() => navigate(-1)}
                    label="Cancel"
                    className="btn btn_danger"
                  />

                  <Components.Button
                    isLoading={isLoading}
                    onClick={onHandleSubmit}
                    label="Submit"
                    className="btn btn_accent ml-1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

	  {showModal && (
        <Components.MultiPurposeModal
          show={showModal}
          onModalClose={() => setShowModal(false)}
          onClick={onNavigateToListPage}
          icon={icon}
          heading={heading}
          text={text}
          subText={subText}
          dangerButtontext={dangerButtontext}
          accentButtontext={accentButtontext}
        />
      )}
    </>
  );
};

export default CreateOrganization;
