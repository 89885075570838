import React, { useEffect, useRef, useState } from "react";
import * as Components from "../../Components";
import { Link } from "react-router-dom";
import $ from "jquery";
import "datatables.net";
import "../../Assets/backend/assets/css/dataTable.css";
import { useAuth } from "../../Contexts/AuthContext";
import { IoAlert } from "react-icons/io5";
import * as API_CONSTANTS from "../../ApiConstants/index";
import axios from "axios";

const Organizations = () => {
  document.title = "Organizations || Hazri Xpert";

  const { user, role } = useAuth();
  const tableRef = useRef(null);
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [data, setData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alertShow, setAlertShow] = useState(false);
  const [jsonMessage, setJsonMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");

  const getData = async () => {
    setIsLoading(true);
    try {
      const response = await axios({
        method: "GET",
        url: API_CONSTANTS.ORGANIZATIONS,
        headers: {
          Authorization: `${user.token.type} ${user.token.value}`,
        },
      });

      if (response.data.status) {
        setData(response.data.data);
      } else {
        setAlertShow(true);
        setJsonMessage(response.data.message);
      }
    } catch (error) {
      const errorMessage =
        error.response?.data.message ||
        "Network error. Please try again later.";
      setAlertShow(true);
      setJsonMessage(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []); 

  useEffect(() => {
    const tableElement = tableRef.current;
    $(tableElement).DataTable({
      language: {
        search: "",
        searchPlaceholder: "Search Here...",
      },
    });
    document.body.classList.toggle("modal-open", showModal);

    const allSelected =
      data.length > 0 && data.every((item) => selectedItems.has(item.id));
    setSelectAll(allSelected);

    return () => {
      $(tableElement).DataTable().destroy();
    };
  }, [showModal, selectedItems, data]);

  const handleCheckboxChange = (id) => {
    setSelectedItems((prevSelectedItems) => {
      const newSelectedItems = new Set(prevSelectedItems);
      if (newSelectedItems.has(id)) {
        newSelectedItems.delete(id);
      } else {
        newSelectedItems.add(id);
      }
      return newSelectedItems;
    });
  };

  const handleSelectAllChange = (e) => {
    if (e.target.checked) {
      setSelectedItems(new Set(data.map((item) => item.id)));
    } else {
      setSelectedItems(new Set());
    }
  };

  const openModal = (type) => {
    setModalType(type);
    setShowModal(true);
  };

  const getModalContent = () => {
    switch (modalType) {
      case "delete":
        return {
          heading: "Delete Organization",
          text: "Are you sure you want to delete this organization?",
          subText:
            "If you delete this organization, you will not be able to revert it.",
          icon: (
            <IoAlert className="nk-modal-icon icon icon-circle icon-circle-xxl bg-orange" />
          ),
          dangerButtontext: "No",
          accentButtontext: "Yes, I'm Sure",
        };
      case "active":
        return {
          heading: "Activate Organization",
          text: "Are you sure you want to activate this organization?",
          subText:
            "Activating this organization will make it visible to users.",
          icon: (
            <IoAlert className="nk-modal-icon icon icon-circle icon-circle-xxl bg-success" />
          ),
          dangerButtontext: "No",
          accentButtontext: "Yes, Activate",
        };
      case "inactive":
        return {
          heading: "Inactivate Organization",
          text: "Are you sure you want to inactivate this organization?",
          subText: "Deactivating this organization will hide it from users.",
          icon: (
            <IoAlert className="nk-modal-icon icon icon-circle icon-circle-xxl bg-warning" />
          ),
          dangerButtontext: "No",
          accentButtontext: "Yes, Inactivate",
        };
      default:
        return {};
    }
  };

  const { heading, text, subText, icon, dangerButtontext, accentButtontext } =
    getModalContent();

  return (
    <>
      <div className="container-fluid">
        <div className="nk-content-inner">
          <div className="nk-content-body">
            <Components.PageHeader
              headingText="Organizations"
              buttonText="Create New Organization"
              buttonIcon="plus"
              buttonLink={`/${role}/organizations/create`}
            />

            <div className="nk-block">
              <div className="card shadow-sm card-stretch">
                <div className="card-inner">
                  {alertShow && (
                    <Components.Alert
                      customClass="col-md-6"
                      type="danger"
                      icon="ni-cross-circle"
                      message={jsonMessage}
                      onClose={() => setAlertShow(false)}
                    />
                  )}

                  {isLoading ? (
                    <div className="text-center">Loading...</div> 
                  ) : (
                    <>
                      <table
                        className="table is-compact table-hover table-striped"
                        ref={tableRef}
                      >
                        <thead>
                          <tr className="nk-tb-item nk-tb-head">
                            <th
                              className="nk-tb-col"
                              style={{ paddingLeft: "1.3rem" }}
                            >
                              <div className="custom-control custom-control-sm custom-checkbox notext">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="selectAll"
                                  checked={selectAll}
                                  onChange={handleSelectAllChange}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="selectAll"
                                />
                              </div>
                            </th>
                            <th className="nk-tb-col text-center">#</th>
                            <th className="nk-tb-col">Name</th>
                            <th className="nk-tb-col tb-col-md text-center">
                              Created At
                            </th>
                            <th className="nk-tb-col tb-col-md text-center">
                              Updated At
                            </th>
                            <th className="nk-tb-col tb-col-md text-center">
                              Status
                            </th>
                            <th className="nk-tb-col text-center">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((item, index) => {
                            const isChecked = selectedItems.has(item.id);
                            return (
                             <>1</>
                            );
                          })}
                        </tbody>
                      </table>
                      <Components.MultiPurposeModal
                        show={showModal}
                        onClose={() => setShowModal(false)}
                        heading={heading}
                        text={text}
                        subText={subText}
                        icon={icon}
                        dangerButtontext={dangerButtontext}
                        accentButtontext={accentButtontext}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Organizations;
