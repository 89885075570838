import React, { useEffect, useState } from "react";
import Data from "../../Data/index.json";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../Contexts/AuthContext";

const SideBar = (props) => {
	const [is_sidebar_menu_compact, setIsSidebarMenuCompact] = useState(false);
	const [openMenus, setOpenMenus] = useState({});

	const location = useLocation();
	const { role } = useAuth();
	const current_path_name = location.pathname;

	const toggleSubMenu = (index) => {
		setOpenMenus((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};

	useEffect(() => {
		localStorage.setItem("is_sidebar_menu_compact", is_sidebar_menu_compact);
		const sidebarElement = document.querySelector("body .nk-sidebar");
		if (sidebarElement) {
			if (is_sidebar_menu_compact) {
				sidebarElement.classList.add("is-compact");
			} else {
				sidebarElement.classList.remove("is-compact");
			}
		}
	}, [is_sidebar_menu_compact]);

	useEffect(() => {
		const updatedOpenMenus = {};
		// Correctly loop through Data.sidebar items
		Data.sidebar.forEach((item, index) => {
			if (isActive(item)) {
				updatedOpenMenus[index] = true;
			}
		});
		setOpenMenus(updatedOpenMenus);
	}, [current_path_name]);

	const isActive = (item) => {
		if (item.sub_items) {
			return (
				current_path_name.split("/")[2] === item.url_link ||
				item.sub_items.some(
					(sub_item) =>
						current_path_name.split("/")[2] === sub_item.url_link.split("/")[0] &&
						current_path_name.split("/")[3] === sub_item.url_link.split("/")[1]
				)
			);
		}
		return current_path_name.split("/")[2] === item.url_link;
	};

	const renderSubItems = (sub_items) => {
		return (
			<ul className="nk-menu-sub" style={{ display: "block" }}>
				{sub_items.map((sub_item, subIndex) => {
					const subItemActive =
						current_path_name.split("/")[2] === sub_item.url_link.split("/")[0] &&
						current_path_name.split("/")[3] === sub_item.url_link.split("/")[1];

					return (
						<li
							key={subIndex}
							className={`nk-menu-item ${subItemActive ? "active current-page" : ""}`}
						>
							<Link to={`/${role}/${sub_item.url_link}`} className="nk-menu-link sidebar_link">
								<span className="nk-menu-text">{sub_item.title}</span>
							</Link>
						</li>
					);
				})}
			</ul>
		);
	};

	const renderParentItemWithSubItems = (item, index) => {
		const itemActive = isActive(item);
		const isOpen = openMenus[index] || false;

		return (
			<li
				key={index}
				className={`nk-menu-item has-sub ${itemActive ? "active current-page" : ""} ${isOpen ? "open" : ""}`}
			>
				<Link
					to="#"
					className="nk-menu-link nk-menu-toggle sidebar_link"
					onClick={() => toggleSubMenu(index)}
				>
					<span className="nk-menu-icon">
						<em className={`icon ni ni-${item.icon}`} />
					</span>
					<span className="nk-menu-text">{item.title}</span>
				</Link>

				{isOpen && item.sub_items && renderSubItems(item.sub_items)}
			</li>
		);
	};

	const renderParentItemWithoutSubItems = (item, index) => {
		const itemActive = isActive(item);

		return (
			<li
				key={index}
				className={`nk-menu-item ${itemActive ? "active current-page" : ""}`}
			>
				<Link to={`/${role}/${item.url_link}`} className="nk-menu-link sidebar_link">
					<span className="nk-menu-icon">
						<em className={`icon ni ni-${item.icon}`} />
					</span>
					<span className="nk-menu-text">{item.title}</span>
				</Link>
			</li>
		);
	};

	return (
		<>
			<div className="nk-sidebar-element nk-sidebar-head">
				<div className="nk-sidebar-brand">
					<Link to={`/${role}/dashboard`} className="logo-link nk-sidebar-logo">
						<img
							className="logo-small logo-img logo-img-small float-left"
							src="/images/logo.png"
							alt="logo-small"
						/>
						<h4 className="text-secondary text-capitalize dashboard_logo mt-1">
							Hazri Xpert
						</h4>
					</Link>
				</div>

				<div className="nk-menu-trigger mr-n2">
					<Link
						to={`${current_path_name}`}
						className="nk-nav-toggle nk-quick-nav-icon d-xl-none"
						data-target="sidebarMenu"
						onClick={() => props.setIsHeaderMenuCompact(false)}
					>
						<em className="icon ni ni-arrow-left"></em>
					</Link>
					<Link
						to={`${current_path_name}`}
						className="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex"
						data-target="sidebarMenu"
						onClick={() => setIsSidebarMenuCompact(!is_sidebar_menu_compact)}
					>
						<em className="icon ni ni-menu"></em>
					</Link>
				</div>
			</div>

			<div className="nk-sidebar-element">
				<div className="nk-sidebar-content">
					<div className="nk-sidebar-menu" data-simplebar>
						<ul className="nk-menu">
							{Data.sidebar && Data.sidebar.length > 0 &&
								Data.sidebar.map((item, index) =>
									item.sub_items
										? renderParentItemWithSubItems(item, index)
										: renderParentItemWithoutSubItems(item, index)
								)}
						</ul>
					</div>
				</div>
			</div>
		</>
	);
};

export default SideBar;
