import { createBrowserRouter } from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes";
import * as Layouts from "../Layouts";
import * as Pages from "../Pages";

const Routes = [
	{
		path: "/",
		element: <ProtectedRoutes allowedRoles={["super-admin", "admin"]} />,
		errorElement: <Pages.NotFound404 />,
		children: [
			{
				path: ":role/dashboard",
				element: <Layouts.MainLayout />,
				children: [
					{ path: "", element: <Pages.Dashboard /> },
					{ path: "*", element: <Pages.NotFound404 /> },
				],
			},
			{
				path: ":role/organizations",
				element: <Layouts.MainLayout />,
				children: [
					{ path: "", element: <Pages.Organizations /> },
					{ path: "create", element: <Pages.CreateOrganization /> },
					{ path: ":_id/edit", element: <Pages.EditOrganization /> },
					{ path: "*", element: <Pages.NotFound404 /> },
				],
			},
			{
				path: ":role/teachers",
				element: <Layouts.MainLayout />,
				children: [
					{ path: "", element: <Pages.Teachers /> },
					{ path: "create", element: <Pages.CreateTeacher /> },
					{ path: ":_id/edit", element: <Pages.EditTeacher /> },
					{ path: "*", element: <Pages.NotFound404 /> },
				],
			},
			{
				path: ":role/programs",
				element: <Layouts.MainLayout />,
				children: [
					{ path: "", element: <Pages.Programs /> },
					{ path: "create", element: <Pages.CreateProgram /> },
					{ path: ":_id/edit", element: <Pages.EditProgram /> },
					{ path: "*", element: <Pages.NotFound404 /> },
				],
			},
			{
				path: ":role/classes",
				element: <Layouts.MainLayout />,
				children: [
					{ path: "", element: <Pages.Classes /> },
					{ path: "create", element: <Pages.CreateClass /> },
					{ path: ":_id/edit", element: <Pages.EditClass /> },
					{ path: "*", element: <Pages.NotFound404 /> },
				],
			},
			{
				path: ":role/sections",
				element: <Layouts.MainLayout />,
				children: [
					{ path: "", element: <Pages.Sections /> },
					{ path: "create", element: <Pages.CreateSection /> },
					{ path: ":_id/edit", element: <Pages.EditSection /> },
					{ path: "*", element: <Pages.NotFound404 /> },
				],
			},
			{
				path: ":role/subjects",
				element: <Layouts.MainLayout />,
				children: [
					{ path: "", element: <Pages.Subjects /> },
					{ path: "create", element: <Pages.CreateSubject /> },
					{ path: ":_id/edit", element: <Pages.EditSubject /> },
					{ path: "*", element: <Pages.NotFound404 /> },
				],
			},
			{
				path: ":role/sessions",
				element: <Layouts.MainLayout />,
				children: [
					{ path: "", element: <Pages.Sessions /> },
					{ path: "create", element: <Pages.CreateSession /> },
					{ path: ":_id/edit", element: <Pages.EditSession /> },
					{ path: "*", element: <Pages.NotFound404 /> },
				],
			},
			{
				path: ":role/enrollments",
				element: <Layouts.MainLayout />,
				children: [
					{
						path: "parents",
						element: <Pages.ParentsList />,
					},
					{
						path: "create",
						element: <Pages.CreateEnrollment />,
					},
					{
						path: ":_id/edit",
						element: <Pages.EditEnrollment />,
					},
					{ path: "*", element: <Pages.NotFound404 /> },
				],
			},
		],
	},
	{
		path: "login",
		element: <Layouts.AuthLayout />,
		children: [
			{ path: "", element: <Pages.Login /> },
			{ path: "*", element: <Pages.NotFound404 /> },
		],
	},
	{
		path: "*",
		element: <Pages.NotFound404 />,
	},
];

const router = createBrowserRouter(Routes);

export default router;
