import React, { useEffect, useState } from "react";
import * as API_CONSTANTS from "../../ApiConstants/index";
import * as Components from "../../Components/index";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../Contexts/AuthContext";

const Login = () => {
	const { loginUser, role, isAuth } = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		if (isAuth) {
			navigate(`/${role}/dashboard`, { replace: true });
		}
	}, [isAuth, navigate, role]);

	const [formFields, setFormData] = useState({
		emailAddress: "",
		password: "",
	});
	const [passwordType, setPasswordType] = useState("password");

	const [errors, setErrors] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [alertShow, setAlertShow] = useState(false);
	const [jsonMessage, setJsonMessage] = useState("");

	const togglePassword = () => {
		setPasswordType(passwordType === "password" ? "text" : "password");
	};

	const validateFields = () => {
		const newErrors = {};

		if (!formFields.emailAddress) newErrors.emailAddress = "Email address is required.";
		if (!formFields.password) newErrors.password = "Password is required.";

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const onHandleSubmit = async () => {
		setIsLoading(true);

		if (!validateFields()) {
			setIsLoading(false);
			return;
		}

		try {
			const bodyParameters = new FormData();
			bodyParameters.append("email_address", formFields.emailAddress);
			bodyParameters.append("password", formFields.password);

			const response = await axios.post(API_CONSTANTS.LOGIN, bodyParameters);

			if (response.data.status === true) {
				loginUser(response.data.data);
				navigate(`/${role}/dashboard`);
			} else {
				setAlertShow(true);
				setJsonMessage(response.data.message);
			}
		} catch (error) {
			setAlertShow(true);
			setJsonMessage(error.response ? error.response.data.message : error.message);
		} finally {
			setIsLoading(false);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	return (
		<>
		<div className="nk-block nk-block-middle nk-auth-body wide-xs NkAuthBody">
			<div className="brand-logo">
				<Link to={`/${role}/dashboard`} className="logo-link d-flex">
					<img
						className="img-fluid"
						width={60}
						height={60}
						src="/images/logo.png"
						alt="logo-small"
					/>
					<h2 className="BrandLogoTitle ml-2 pt-0">Hazri Xpert</h2>
				</Link>
			</div>
			<hr />
			<div className="card border-0">
				<div className="card-inner card-inner-sm p-0">
					<div className="nk-block-head">
						<div className="nk-block-head-content">
							<h5 className="nk-block-title">Log In!</h5>
							<div className="nk-block-des">
								<p>
									Access the Hazri Xpert by using your Email Address or
									Username & Password.
								</p>
							</div>
						</div>
					</div>

					{alertShow === true && (
						<Components.Alert
							type="danger"
							icon="ni-cross-circle"
							message={jsonMessage}
							onClose={() => setAlertShow(false)}
						/>
					)}

					<Components.UnifiedInputField
						label="Email Address"
						type="text"
						name="emailAddress"
						value={formFields.emailAddress}
						onChange={handleChange}
						error={errors.emailAddress}
						placeholder="Enter Your Email Address"
					/>

					<Components.UnifiedInputField
						label="Password"
						type={passwordType}
						name="password"
						value={formFields.password}
						onChange={handleChange}
						error={errors.password}
						placeholder="Enter Your Password"
						togglePassword={togglePassword}
						passwordType={passwordType}
					/>

					<div className="form-group text-center">
						<Components.Button
							isLoading={isLoading}
							onClick={onHandleSubmit}
							label="Log In"
							id="login"
							className="btn btn_primary"
						/>
					</div>
				</div>
			</div>
		</div>
	</>
	);
};

export default Login;
