import React, { useEffect, useRef, useState } from "react";
import * as Components from "../../Components";
import { Link } from "react-router-dom";
import $ from 'jquery';
import 'datatables.net';
import '../../Assets/backend/assets/css/dataTable.css';
import { useAuth } from "../../Contexts/AuthContext";
import Data from "../../Data/index.json";
import { IoAlert } from "react-icons/io5";

const Classes = () => {
	document.title = "Classes || Hazri Xpert";

	const { role } = useAuth();
	const tableRef = useRef(null);
	const [selectedItems, setSelectedItems] = useState(new Set());
	const [selectAll, setSelectAll] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [modalType, setModalType] = useState("");

	useEffect(() => {
		const tableElement = tableRef.current;
		$(tableElement).DataTable({
			language: {
				search: "",
				searchPlaceholder: "Search Here..."
			},
		});
		document.body.classList.toggle('modal-open', showModal);
		const allSelected = Data.classes.length > 0 && Data.classes.every(item => selectedItems.has(item._id));
		setSelectAll(allSelected);

		return () => {
			$(tableElement).DataTable().destroy();
		};
	}, [showModal, selectedItems]);


	const handleCheckboxChange = (_id) => {
		setSelectedItems((prevSelectedItems) => {
			const newSelectedItems = new Set(prevSelectedItems);
			if (newSelectedItems.has(_id)) {
				newSelectedItems.delete(_id);
			} else {
				newSelectedItems.add(_id);
			}
			return newSelectedItems;
		});
	};

	const handleSelectAllChange = (e) => {
		if (e.target.checked) {
			setSelectedItems(new Set(Data.classes.map(item => item._id)));
		} else {
			setSelectedItems(new Set());
		}
	};

	const openModal = (type) => {
		setModalType(type);
		setShowModal(true);
	};

	const getModalContent = () => {
		switch (modalType) {
			case 'delete':
				return {
					heading: "Delete Class",
					text: "Are you sure you want to delete this class?",
					subText: "If you delete this class, you will not be able to revert it.",
					icon: <IoAlert className="nk-modal-icon icon icon-circle icon-circle-xxl bg-orange" />,
					dangerButtontext: "No",
					accentButtontext: "Yes, I'm Sure"
				};
			case 'active':
				return {
					heading: "Activate Class",
					text: "Are you sure you want to activate this class?",
					subText: "Activating this class will make it visible to users.",
					icon: <IoAlert className="nk-modal-icon icon icon-circle icon-circle-xxl bg-success" />,
					dangerButtontext: "No",
					accentButtontext: "Yes, Activate"
				};
			case 'inactive':
				return {
					heading: "Inactivate Class",
					text: "Are you sure you want to inactivate this class?",
					subText: "Deactivating this class will hide it from users.",
					icon: <IoAlert className="nk-modal-icon icon icon-circle icon-circle-xxl bg-warning" />,
					dangerButtontext: "No",
					accentButtontext: "Yes, Inactivate"
				};
			default:
				return {};
		}
	};
	const { heading, text, subText, icon, dangerButtontext, accentButtontext } = getModalContent();

	return (
		<>
			<div className="container-fluid">
				<div className="nk-content-inner">
					<div className="nk-content-body">
						<Components.PageHeader
							headingText="Classes"
							buttonText="Create New Class"
							buttonIcon="plus"
							buttonLink={`/${role}/classes/create`}
						/>

						<div className="nk-block">
							<div className="card shadow-sm card-stretch">
								<div className="card-inner">
									<table className="table is-compact table-hover table-striped" ref={tableRef}>
										<thead>
											<tr className="nk-tb-item nk-tb-head">
												<th className="nk-tb-col" style={{ paddingLeft: '1.3rem' }}>
													<div className="custom-control custom-control-sm custom-checkbox notext">
														<input
															type="checkbox"
															className="custom-control-input"
															id="selectAll"
															checked={selectAll}
															onChange={handleSelectAllChange}
														/>
														<label
															className="custom-control-label"
															htmlFor="selectAll"
														/>
													</div>
												</th>
												<th className="nk-tb-col text-left">#</th>
												<th className="nk-tb-col">Class Name</th>
												<th className="nk-tb-col">Program Name</th>
												<th className="nk-tb-col tb-col-md text-center">Created At</th>
												<th className="nk-tb-col tb-col-md text-center">Updated At</th>
												<th className="nk-tb-col tb-col-md text-center">Status</th>
												<th className="nk-tb-col text-center">Actions</th>
											</tr>
										</thead>
										<tbody>
											{Data.classes && Data.classes.length > 0 &&
												Data.classes.map((item, index) => {
													const isChecked = selectedItems.has(item._id);
													return (
														<tr className="tb-tnx-item" key={index}>
															<td className="nk-tb-col">
																<div className="custom-control custom-control-sm custom-checkbox notext">
																	<input
																		type="checkbox"
																		className="custom-control-input"
																		id={`class_id_${item._id}`}
																		checked={isChecked}
																		onChange={() => handleCheckboxChange(item._id)}
																	/>
																	<label
																		className="custom-control-label"
																		htmlFor={`class_id_${item._id}`}
																	/>
																</div>
															</td>
															<td className="nk-tb-col text-left">{index < 9 ? "0" + (index + 1) : index + 1}</td>
															<td className="nk-tb-col">{item.name}</td>
															<td className="nk-tb-col">{item.program_name}</td>
															<td className="nk-tb-col tb-col-md text-center">{item.created_at}</td>
															<td className="nk-tb-col tb-col-md text-center">{item.updated_at}</td>
															<td className="nk-tb-col tb-col-md text-center">
																<span
																	className={`badge badge-pill badge_${item.status === 0 ? "danger" : "success"}_dim`}
																>
																	{item.status === 0 ? "Inactive" : "Active"}
																</span>
															</td>
															<td className="nk-tb-col text-center">
																<Link
																	to={`/${role}/classes/${item._id}/edit`}
																	data-toggle="tooltip"
																	title="Edit Class"
																	className="list_icon_style mr-2"
																>
																	<em className="icon ni ni-edit-fill text-azure" />
																</Link>
																{item.status === 0 ? (
																	<Link
																		to="#"
																		onClick={(e) => {
																			e.preventDefault();
																			openModal('active');
																		}}
																		data-toggle="tooltip"
																		title="Seet Class as Active"
																		className="list_icon_style mr-2"
																	>
																		<em className="icon ni ni-check-circle-fill text-success" />
																	</Link>
																) : (
																	<Link
																		to="#"
																		onClick={(e) => {
																			e.preventDefault();
																			openModal('inactive');
																		}}
																		data-toggle="tooltip"
																		title="Set Class as Inactive"
																		className="list_icon_style mr-2"
																	>
																		<em className="icon ni ni-cross-circle-fill text-orange" />
																	</Link>
																)}
																<Link
																	to="#"
																	onClick={(e) => {
																		e.preventDefault();
																		openModal('delete');
																	}}
																	data-toggle="tooltip"
																	title="Delete Class"
																	className="list_icon_style"
																>
																	<em className="icon ni ni-trash-fill text-danger" />
																</Link>
															</td>
														</tr>
													);
												})
											}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{showModal && (
				<Components.MultiPurposeModal
					show={showModal}
					onModalClose={() => setShowModal(false)}
					onClick={() => setShowModal(false)}
					icon={icon}
					heading={heading}
					text={text}
					subText={subText}
					dangerButtontext={dangerButtontext}
					accentButtontext={accentButtontext}
				/>
			)}
		</>
	);
};

export default Classes;
