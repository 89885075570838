import React, { useState } from "react";
import * as Components from "../../Components";
// import * as API_CONSTANTS from "../../ApiConstants/index";
// import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Contexts/AuthContext";
import DummyImage from "../../Assets/backend/images/dummey_profile.jpeg";
import Data from "../../Data/index.json";

const EditTeacher = () => {
	// Site Title...
	document.title = "Edit & Update Teacher || Hazri Xpert";

	const [formFields, setFormData] = useState({
		full_name: "",
		contact_no: "",
		password: "",
		email_address: "",
		cnic: "",
		experience: "",
		speciality: "",
		salary: "",
		date_of_birth: "",
		join_date: "",
		gender: "",
		image: "",
		about_information: "",
		address: "",
	});

	const [password_type, setPasswordType] = useState("password");
	const [image_preview, setImagePreview] = useState(DummyImage);
	const [errors, setErrors] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [alertShow, setAlertShow] = useState(false);
	const [jsonMessage, setJsonMessage] = useState("");

	const navigate = useNavigate();

	const { role } = useAuth();

	const togglePassword = () => {
		setPasswordType(password_type === "password" ? "text" : "password");
	};

	const validateFields = () => {
		const newErrors = {};

		if (!formFields.name) {
			newErrors.name = "Teacher name is required.";
		}

		setErrors(newErrors);

		return Object.keys(newErrors).length === 0;
	};

	const handleContactNoChange = (value, country) => {
		const dialCodeLength = country.dialCode.length;
		const formatLength = country.format.replace(/ /g, "").length;

		const isValidLength = value.length + dialCodeLength === formatLength;

		setFormData((prev) => ({
			...prev,
			contact_no: value,
		}));

		const error = isValidLength
			? null
			: "Invalid contact number. Please ensure it includes the country code and follows the correct format.";
		setErrors((prev) => ({
			...prev,
			contact_no: error,
		}));
	};

	const onHandleSubmit = async () => {
		setIsLoading(true);

		if (!validateFields()) {
			setIsLoading(false);
			return;
		}

		try {
			// const bodyParameters = new FormData();
			// bodyParameters.append("name", formFields.name);
			// const response = await axios({
			// method: "POST",
			// url: API_CONSTANTS.STORE_PROGRAM,
			// headers: {
			// "Authorization": `Bearer ${token}`,
			// "Access-Control-Allow-Origin": "*",
			// },
			// data: bodyParameters,
			// });
			// if (response.data.status === true) {
			// setIsLoading(false);
			// setAlertShow(false);
			// navigate(`/${role + "/programs"}`);
			// } else {
			// setIsLoading(false);
			// setAlertShow(true);
			// setJsonMessage(response.data.message);
			// }
		} catch (error) {
			setIsLoading(false);
			setAlertShow(true);
			setJsonMessage(
				error.response ? error.response.data.message : error.message
			);
		}
	};

	const handleChange = (e) => {
		const { name, value, type, files } = e.target;

		if (type === "file") {
			const file = files[0];

			setFormData((prev) => ({ ...prev, [name]: file }));

			if (file) {
				setImagePreview(URL.createObjectURL(file));
			} else {
				setImagePreview(DummyImage);
			}
		} else {
			setFormData((prev) => ({ ...prev, [name]: value }));
		}

		setFormData((prev) => ({ ...prev, [name]: value }));

		if (name === "name") {
			if (!value) {
				setErrors((prev) => ({
					...prev,
					name: "Teacher name is required.",
				}));
			} else {
				setErrors((prev) => ({ ...prev, name: "" }));
			}
		}
	};

	return (
		<>
			<div className="container-fluid">
				<div className="nk-content-inner">
					<div className="nk-content-body">
						<Components.PageHeader
							headingText="Edit & Update Teacher"
							buttonText="Teachers List"
							buttonIcon="list"
							buttonLink={`/${role}/teachers`}
						/>

						<div className="nk-block">
							<div className="card shadow-sm card-stretch">
								<div className="card-inner">
									{alertShow === true && (
										<Components.Alert
											customClass="col-md-6"
											type="danger"
											icon="ni-cross-circle"
											message={jsonMessage}
											onClose={() => setAlertShow(false)}
										/>
									)}

									<div className="row">
										<div className="col-md-4 col-12 mb-3">
											<Components.UnifiedInputField
												label="Full Name"
												type="text"
												name="full_name"
												value={formFields.full_name}
												onChange={handleChange}
												error={errors.full_name}
												placeholder="Enter Full Name"
												required={true}
											/>
										</div>

										<div className="col-md-4 col-12 mb-3">
											<Components.UnifiedInputField
												inputType="phone"
												label="Contact Number"
												type="text"
												name="contact_no"
												value={formFields.contact_no}
												onChange={(value, country) =>
													handleContactNoChange(value, country)
												}
												placeholder="Enter Contact Number"
												error={errors.contact_no}
												className="phone_input"
												country={"pk"}
												enableSearch={true}
												required={true}
											/>
										</div>

										<div className="col-md-4 col-12 mb-3">
											<Components.UnifiedInputField
												label="Password"
												type="password"
												name="password"
												value={formFields.password}
												onChange={handleChange}
												error={errors.password}
												placeholder="Enter Password"
												togglePassword={togglePassword}
												password_type={password_type}
												required
											/>
										</div>

										<div className="col-md-4 col-12 mb-3">
											<Components.UnifiedInputField
												inputType="input"
												label="Email Address"
												type="text"
												name="email_address"
												value={formFields.email_address}
												onChange={handleChange}
												error={errors.email_address}
												placeholder="Enter Email Address"
												required={false}
											/>
										</div>

										<div className="col-md-4 col-12 mb-3">
											<Components.UnifiedInputField
												inputType="input"
												label="CNIC"
												type="number"
												name="cnic"
												value={formFields.cnic}
												onChange={handleChange}
												error={errors.cnic}
												placeholder="Enter CNIC"
												required={false}
											/>
										</div>

										<div className="col-md-4 col-12 mb-3">
											<Components.UnifiedInputField
												label="Experience"
												type="text"
												name="experience"
												value={formFields.experience}
												onChange={handleChange}
												error={errors.experience}
												placeholder="Enter Experience"
											/>
										</div>

										<div className="col-md-4 col-12 mb-3">
											<Components.UnifiedInputField
												label="Speciality"
												type="text"
												name="speciality"
												value={formFields.speciality}
												onChange={handleChange}
												error={errors.speciality}
												placeholder="Enter Speciality"
											/>
										</div>

										<div className="col-md-4 col-12 mb-3">
											<Components.UnifiedInputField
												label="Education"
												type="text"
												name="education"
												value={formFields.education}
												onChange={handleChange}
												error={errors.education}
												placeholder="Enter Education"
											/>
										</div>

										<div className="col-md-4 col-12 mb-3">
											<Components.UnifiedInputField
												label="Salary"
												type="number"
												name="salary"
												value={formFields.salary}
												onChange={handleChange}
												error={errors.salary}
												placeholder="Enter Salary"
											/>
										</div>

										<div className="col-md-4 col-12 mt-3">
											<Components.UnifiedInputField
												label="Date of Birth"
												type="date"
												name="date_of_birth"
												value={formFields.date_of_birth}
												onChange={handleChange}
												error={errors.date_of_birth}
												placeholder="Enter Date of Birth"
												required={false}
											/>
										</div>

										<div className="col-md-4 col-12 mt-3">
											<Components.UnifiedInputField
												label="Join Date"
												type="date"
												name="join_date"
												value={formFields.join_date}
												onChange={handleChange}
												error={errors.join_date}
												placeholder="Enter Join Date"
												required={false}
											/>
										</div>

										<div className="col-md-4 col-12 mt-3">
											<Components.UnifiedInputField
												inputType="select"
												label="Gender"
												type="select"
												name="gender"
												value={formFields.gender}
												onChange={handleChange}
												error={errors.gender}
												placeholder="Select Gender"
												required={false}
												options={Data.gender}
											/>
										</div>

										<div className="col-md-11 col-12 mb-3">
											<Components.UnifiedInputField
												inputType="file"
												label="Image"
												type="file"
												name="image"
												value={formFields.image}
												onChange={handleChange}
												error={errors.image}
												placeholder="Upload Image"
												required={false}
												accept="image/*"
											/>
										</div>

										<div className="col-12 col-md-1 mt-3 text-center">
											<img
												src={image_preview}
												alt="Teacher_Image"
												className="rounded-circle"
												height="80"
												width="80"
											/>
										</div>

										<div className="col-12 mt-3">
											<Components.UnifiedInputField
												inputType="ckeditor"
												label="About Information"
												type="textarea"
												name="about_information"
												value={formFields.about_information}
												onChange={handleChange}
												error={errors.about_information}
												placeholder="Enter About Information"
												required={false}
											/>
										</div>

										<div className="col-12 mt-3">
											<Components.UnifiedInputField
												inputType="ckeditor"
												label="Address"
												type="textarea"
												name="address"
												value={formFields.address}
												onChange={handleChange}
												error={errors.address}
												placeholder="Enter Address"
												required={false}
											/>
										</div>
									</div>

									<hr />
									<Components.Button
										isLoading={isLoading}
										onClick={() => navigate(-1)}
										label="Cancel"
										className="btn btn_danger"
									/>

									<Components.Button
										isLoading={isLoading}
										onClick={onHandleSubmit}
										label="Update"
										className="btn btn_accent ml-1"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditTeacher;
