import React, { useState } from "react";
import * as Components from "../../Components";
import { Link } from "react-router-dom";
import { useAuth } from "../../Contexts/AuthContext";

const EditEnrollment = () => {
	document.title = "Edit & Update Enrollment || Hazri Xpert";

	const steps = [
		{ title: "Parent/Guardian Details", icon: "users-fill" },
		{ title: "Student Admission Details", icon: "user-alt" },
		// { title: "Fees Details", icon: "coins" },
	];

	const [currentStep, setCurrentStep] = useState(0);
	const [completedSteps, setCompletedSteps] = useState(
		new Array(steps.length).fill(false)
	)
	const { role } = useAuth();

	const goToNextStep = () => {
		setCompletedSteps((prevSteps) => {
			const newSteps = [...prevSteps];
			newSteps[currentStep] = true;
			return newSteps;
		});
		setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
	};

	const goToPreviousStep = () => {
		setCurrentStep((prevStep) => {
			const newStep = Math.max(prevStep - 1, 0);

			setCompletedSteps((prevSteps) => {
				const newSteps = [...prevSteps];
				if (newStep < prevStep) {
					newSteps[prevStep] = false;
				}
				return newSteps;
			});

			return newStep;
		});
	};

	return (
		<>
			<div className="container-fluid">
				<div className="nk-content-inner">
					<div className="nk-content-body">
						<Components.PageHeader
							headingText="Edit & Update Enrollment"
							buttonText="Parents List"
							buttonIcon="list"
							buttonLink={`/${role}/enrollments/parents`}
						/>

						<div className="nk-block">
							<div className="card shadow-sm card-stretch">
								<div className="card-inner">
									<div className="nk-wizard nk-wizard-simple is-alter wizard">
										<div className="steps">
											<ul>
												{steps.map((step, index) => (
													<li
														key={index}
														className={
															currentStep === index
																? "current"
																: completedSteps[index]
																	? "current"
																	: ""
														}
													>
														<Link to="#">
															<span className="number">
																<em className={`icon ni ni-${step.icon}`}></em>
															</span>
															<h5>{step.title}</h5>
														</Link>
													</li>
												))}
											</ul>
										</div>

										<div className="content clearfix">
											<div className="nk-wizard-content body current">
												{currentStep === 0 && (
													<Components.ParentOrGuardianForm goToNextStep={goToNextStep} />
												)}

												{currentStep === 1 && (
													<Components.StudentForm goToNextStep={goToNextStep} goToPreviousStep={goToPreviousStep} />
												)}

												{currentStep === 2 && (
													<h2>Fee Details</h2>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditEnrollment;
