import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as API_CONSTANTS from "../../ApiConstants/index";
import DummyImage from "../../Assets/backend/images/dummey_profile.jpeg";
import axios from "axios";
import { useAuth } from "../../Contexts/AuthContext";

const Header = (props) => {
	const { user, role, logoutUser } = useAuth();
	const [isHeaderDropdownOpen, setIsHeaderDropdownOpen] = useState(false);

	const location = useLocation();
	const navigate = useNavigate();
	const currentPathName = location.pathname;

	useEffect(() => {
		localStorage.setItem("is_header_dropdown_open", isHeaderDropdownOpen);
		if (isHeaderDropdownOpen) {
			document.querySelector(".dropdown-toggle").classList.add("show");
			document.querySelector(".dropdown-menu-right").classList.add("show");
		} else {
			document.querySelector(".dropdown-toggle").classList.remove("show");
			document.querySelector(".dropdown-menu-right").classList.remove("show");
		}
	}, [isHeaderDropdownOpen]);

	const onHandleClick = async () => {
		try {
			const response = await axios({
				method: "POST",
				url: API_CONSTANTS.LOGOUT,
				headers: {
					Authorization: `${user.token.type} ${user.token.value}`,
					"X-Device": `${user.device}`,
				}
			});

			if (response.data.status === true) {
				logoutUser();
				navigate("/login");
			}
		} catch (error) {
			console.log(error.response ? error.response.data.message : error.message);
		}
	};

	return (
		<>
			<div className="container-fluid">
				<div className="nk-header-wrap">
					<div className="nk-menu-trigger d-xl-none ml-n1">
						<Link
							to={`${currentPathName}`}
							className="nk-nav-toggle nk-quick-nav-icon"
							data-target="sidebarMenu"
							onClick={() => props.setIsHeaderMenuCompact(true)}
						>
							<em className="icon ni ni-menu"></em>
						</Link>
					</div>
					<div className="nk-header-brand d-xl-none">
						<Link href={`/${role}/dashboard`} className="logo-link nk-sidebar-logo">
							<img
								className="logo-small logo-img logo-img-small float-left"
								src="/images/logo.png"
								alt="logo-small"
							/>

							<h3 className="text-primary text-capitalize dashboard_logo mt-1">
								Hazri Xpert
							</h3>
						</Link>
					</div>

					<div className="nk-header-tools">
						<ul className="nk-quick-nav">
							<li className="dropdown user-dropdown">
								<div
									className="dropdown-toggle mr-n1 cursor_pointer"
									data-toggle="dropdown"
									onClick={() => setIsHeaderDropdownOpen(!isHeaderDropdownOpen)}
								>
									<div className="user-toggle">
										<div className="user-avatar sm">
											{user && user.user.image ? (
												<img
													width="40px"
													height="40px"
													src={user.user.image}
													alt="profile_image"
												/>
											) : (
												<img
													width="32px"
													height="32px"
													src={DummyImage}
													alt="profile_image"
												/>
											)}
										</div>
										<div className="user-info d-none d-xl-block">
											<div className="user-status user-status-unverified">
												{user ? user.user.role.name : ""}!
											</div>
											<div className="user-name dropdown-indicator">
												{user ? user.user.full_name : ""}
											</div>
										</div>
									</div>
								</div>
								<div className="dropdown-menu dropdown-menu-md dropdown-menu-right">
									<div className="dropdown-inner user-card-wrap bg-lighter">
										<div className="user-card">
											<div className="user-avatar">
												{user && user.user.image ? (
													<img
														width="40px"
														height="40px"
														src={user.user.image}
														alt="profile_image"
													/>
												) : (
													<img
														width="40px"
														height="40px"
														src={DummyImage}
														alt="profile_image"
													/>
												)}
											</div>
											<div className="user-info">
												<span className="lead-text">{user ? user.user.full_name : ""}</span>
												<span className="sub-text">{user ? user.user.email_address : ""}</span>
											</div>
										</div>
									</div>

									<div className="dropdown-inner">
										<ul className="link-list">
											<li>
												<Link to="#" onClick={onHandleClick}>
													<em className="icon ni ni-signout"></em>
													<span>Log Out</span>
												</Link>
											</li>
										</ul>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
};

export default Header;
